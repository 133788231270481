.image-profile{
    width: 25vh;    
}

.loader-div{
    width: 100%;
    position: absolute;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: rgba(255,255,255, .5);
    text-align: center;
    padding-top: 300px;
    color: #666;
    font-size: 40px;
}